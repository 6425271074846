import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Mint from './components/Mint';
import Battle from './components/Battle';
import Navigation from './components/Navigation';

function App() {
  return (
    <BrowserRouter>
      <div>
        <Navigation />
          <Routes>
           <Route path="/" element={<Home/>} exact />
           <Route path="/mint" element={<Mint/>} exact />
           <Route path="*" element={<Battle/>} exact />
         </Routes>
      </div> 
    </BrowserRouter>
  );
}

export default App;

