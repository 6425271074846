import React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

const battle = () => {
    return (
       <Container>
            <h1>Welcome!</h1>

            <p>This is the Warriors NFT <strong>Beta</strong> web application where you can customize
           and fight with warriors you've minted on the blockchain.</p>

            <h3>How does this work?</h3>

            <Box component="img" src="/images/castle_bg.png" sx={{
                width: 300,
                float: 'right',
                'box-shadow': '#333 10px 10px 10px',
                margin: '1em'
            }} />

            <p>This is the start of something unique. We don't yet know how this will take shape.</p>

            <p>We do know it will take community support. We need your help!</p>

            <h3>The idea</h3>

            <p>Your animal warriors participate in virtual battles that occur at
            the end of each week. Leading up to the battle you have a daily
            allocated allowance of moves you can make.</p>

            <p>Victory in battle garners fame and recognition. Warriors accumulate a history
            making them even more unique.</p>
            
            <p>Each battle is a part of long running seasons.</p>
            
            <p>Only the best warriors make it to the top. Will one of your
            warriors be a season champion?</p>
            
            <h3>How to contribute</h3>

            <p><a href="https://discord.com/invite/yqyvgXD9nz">Join our Discord</a> to learn how to participate in the beta, foster the community, and help define the feature roadmap. <a href="/mint">Mint a warrior</a> to get started!</p>
       </Container>
    );
}
 
export default battle;
