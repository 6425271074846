import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Slider from '@mui/material/Slider';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import { useMetaMask } from 'metamask-react';
import { ethers } from 'ethers';
import Modal from '@mui/material/Modal';
import Link from '@mui/material/Link';
import CircularProgress from '@mui/material/CircularProgress';
import SlothWarrior from '@warriors-nft/contracts/artifacts/contracts/SlothWarrior.sol/SlothWarrior.json';
import BearWarrior from '@warriors-nft/contracts/artifacts/contracts/BearWarrior.sol/BearWarrior.json';

const Item = styled(Paper)(({ theme }) => ({
   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
   ...theme.typography.body2,
   padding: theme.spacing(1),
   textAlign: 'center',
   color: theme.palette.text.secondary,
 }));

const ImageButton = styled(ButtonBase)(({ theme }) => ({
   '& .MuiBox-root': {
      maxWidth: '100%',
   },
   '&:hover, &.Mui-focusVisible': {
     zIndex: 1,
     '& .MuiPaper-root': { background: '#efefef', cursor: 'pointer' }
   },
 }));

 const modalStyle = {
   position: 'absolute',
   top: '50%',
   left: '50%',
   transform: 'translate(-50%, -50%)',
   width: 400,
   bgcolor: 'background.paper',
   border: '2px solid #000',
   boxShadow: 24,
   p: 4,
 };


 const warriorMeta = [{
   id: 'sloth',
   address: {
      mainnet: '0x513b144C49057Eb9710D2fF846a93F6d3bff34E9',
      rinkeby: '0xb0EFa32aBeaf31D61EdCeCa2e1f5dc7588537F84'
   },
   openseas: 'https://opensea.io/collection/warriors-nft-sloths',
   rarible: 'https://rarible.com/slothwarriors/',
   price: '.06 ETH',
   abi: SlothWarrior.abi,
   name: 'Sloth Warrior',
   img: 'images/sloth.png',
   mintable: true
 }, {
   id: 'bear',
   address: {
      mainnet: '0x1b1b6A4E29061b9fC5e442425e1fc7e069a47690',
      rinkeby: '0x4d103D53aF29a3B47631C437460E811923A8275a'
   },
   openseas: 'https://opensea.io/collection/warriors-nft-bears',
   abi: BearWarrior.abi,
   price: '.01 ETH (Limited time only!)',
   name: 'Bear Warrior',
   img: 'images/bear.png',
   mintable: true
 }, {
   id: 'hamster',
   name: 'Hamster Warrior',
   price: '??? ETH',
   img: 'images/hamster.png',
   mintable: false
 }];

const getMeta = (id) => {
   return warriorMeta.find((meta) => meta.id === id);
}

const getAddress = (id, isRinkeby = false) => {
   const meta = getMeta(id);
   const address = isRinkeby ? meta.address.rinkeby : meta.address.mainnet;
   return `https://${isRinkeby ? 'rinkeby.' : ''}etherscan.io/address/${address}`;
}

 const getTokenIds = (receipt) => {
   const transferEvents = receipt.events.filter((e) => (e.event === 'Transfer'));
   const results = [];
   for (const e of transferEvents) {
       results.push(e.args[2].toString());
   }
   return results;
 }

const Mint = () => {
   const [searchParams] = useSearchParams();
   const [selectedWarriorId, setSelectedWarriorId] = useState('');
   const [hoveredWarrior, setHoveredWarrior] = useState('');
   const [numToMint, setNumToMint] = React.useState(1);
   const [openModal, setOpenModal] = React.useState(false);
   const [transactionState, setTransactionState] = React.useState([]);
   const { connect, status, ethereum, chainId } = useMetaMask();
   const isRinkeby = searchParams.get('useRinkeby') === '1';

   const handleOpenModal = () => setOpenModal(true);
   const handleCloseModal = () => setOpenModal(false);

   const MintComponent = () => {

      const handleChange = (event, newValue) => {
         setNumToMint(newValue);
      };

      const mint = async (id) => {
         setTransactionState([]);
         const meta = getMeta(id);
         handleOpenModal();
         try {
            const provider = new ethers.providers.Web3Provider(ethereum);
            const signer = provider.getSigner();
            const contract = new ethers.Contract(meta.address[isRinkeby ? 'rinkeby' : 'mainnet'], meta.abi, signer);
            const price = (await contract.callStatic.publicPrice()) * numToMint;
            const tx = await contract.publicMint(numToMint, { value: String(price) });
            setTransactionState(arr => [...arr, { msg: 'Transaction sent', url: `https://${isRinkeby ? 'rinkeby.' : ''}etherscan.io/tx/${tx.hash}` }]);
            setTransactionState(arr => [...arr, { msg: 'Waiting for receipt...' }]);
            console.log('transaction hash', tx.hash);
            const receipt = await tx.wait();
            setTransactionState(arr => [...arr, { msg: 'Receipt received' }]);
            console.log('transaction receipt', receipt);
            setTransactionState(arr => [...arr, { msg: 'Success!', success: true }]);
            const tokenids = getTokenIds(receipt);
            console.log('token ids minted', tokenids);
         } catch (e) {
            setTransactionState([{ msg: e.toString(), error: true }]);
            console.error(e);
         }
      }

      const isConnected = status === 'connected';
      const isOnRightChain = (isRinkeby && chainId === '0x4') || (!isRinkeby && chainId === '0x1');
      const isMintable = isConnected && isOnRightChain;

      if (selectedWarriorId) {
         return <Container maxWidth="sm">
            <Box sx={{ m: 3 }} />
            <Typography>How many would you like to mint?</Typography>
            <Slider
               value={numToMint}
               valueLabelDisplay="auto"
               onChange={handleChange}
               step={1}
               marks
               min={1}
               max={10}
               />
            <Box sx={{ m: 3 }} />
            <Box sx={{textAlign: 'center'}}>
               <Button disabled={!isMintable} variant="contained" onClick={() => mint(selectedWarriorId)}>Mint {numToMint}</Button>
               {!isConnected && 
                  <Typography sx={{ m: 1}} variant='subtitle2'>Please <button onClick={connect}>connect</button> your metamask wallet</Typography>
          
               }
               {!isOnRightChain && 
                  <Typography sx={{ m: 1}} variant='subtitle2'>Please connect to {isRinkeby ? 'rinkeby' : 'mainnet'}</Typography>
               }
            </Box>
         </Container>
      }
      return <Box />
   }

   const step1 = selectedWarriorId ? `You've selected the ${selectedWarriorId} warrior` : 'Choose a warrior class!';
   const transactionFailed = !!transactionState.filter((t) => t.error).length;
   const transactionSuccess = transactionState.length > 3 && !transactionFailed;
   const transactionFinished = transactionFailed || transactionSuccess;
   return (
      <Container maxWidth='md'>
         <Box sx={{ m: 2 }} />
         <Typography variant='h3' align='center'>Mint</Typography>
         <Box sx={{ m: 2 }} />
         <Typography align='center'>{step1}</Typography>
         <Box sx={{ m: 2 }} />

         <Grid container spacing={1} justifyContent="space-evenly">
            {warriorMeta.map((wc) => (
               <Grid item sm={4} lg={3} key={wc.id} >
                  <ImageButton disabled={!wc.mintable} onClick={() => setSelectedWarriorId(wc.id)} onMouseEnter={() => setHoveredWarrior(wc.id)}>
                     {!wc.mintable &&
                        <Box sx={{
                           position: 'absolute',
                           zIndex: 1,
                           fontWeight: 'bold'
                        }}><Typography>Coming soon!</Typography></Box>
                     }
                     <Item sx={{background: selectedWarriorId === wc.id ? '#efefef' : 'none', opacity: (!wc.mintable || (selectedWarriorId && selectedWarriorId !== wc.id) || (!selectedWarriorId && hoveredWarrior !== wc.id)) ? '30%' : '100%'}}>
                        <Box component="img" src={wc.img} />
                        <Typography>{wc.name}</Typography>
                     </Item>
                  </ImageButton>
               </Grid>
            ))} 
         </Grid>
         {selectedWarriorId !== '' &&
            <Box sx={{ m: 2 }} align='center'>
               <Typography align="center" sx={{ m: 2, color: '#777', fontStyle: 'italic', fontSize: '80%' }} variant="subtitle2">Note these images are just examples. Minted NFT appearance and traits have been randomly generated.</Typography>
               <Typography align="center">
                  <strong>Price: </strong> {getMeta(selectedWarriorId).price}
               </Typography>
               <Typography align="center">
                  View {selectedWarriorId}'s contract on&nbsp; 
                  <Link href={getAddress(selectedWarriorId, isRinkeby)} target="_blank" color="inherit">
                     etherscan
                  </Link>
                  &nbsp;and&nbsp;
                  <Link href={getMeta(selectedWarriorId).openseas} target="_blank" color="inherit">
                     openseas
                  </Link>
               </Typography>
            </Box>
         }
         <MintComponent />
         <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Box sx={modalStyle}>
               <Typography id="modal-modal-title" variant="h6" component="h2" align="center">
                  {!transactionFinished &&
                     <div>Minting your warrior(s)!</div>
                  }
                  {transactionSuccess &&
                     <div>{numToMint} warriors minted!</div>
                  }
                  {transactionFailed &&
                     <div>Failed to mint</div>
                  }
               </Typography>
               {!transactionFinished && 
                  <Box sx={{ m: 2, textAlign: 'center' }}><CircularProgress /></Box>
               }
               {transactionState.map((state, index) => (
                  <Box key={index} id="modal-modal-description" sx={{ mt: 2 }}>
                     {state.url && 
                        <Link href={state.url} target="_blank">
                           {state.msg}
                        </Link>
                     }
                     {!state.url &&
                        <Typography sx={{ overflow: 'hidden' }}>{state.msg}</Typography>
                     }
                  </Box>
               ))}
            </Box>
         </Modal>
         <Box sx={{height: '100px'}} />
       </Container>
    );
}
 
export default Mint;
