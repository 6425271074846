import React from 'react';
import { NavLink } from 'react-router-dom';
import { useMetaMask } from "metamask-react";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

const styleLink = ({ isActive }) => {
  const style = {
    color: '#fff',
    textDecoration: 'none'
  }
  if (isActive) {
    style.textDecoration = 'underline';
  }
  return style;
};

const Navigation = () => {
  const { connect } = useMetaMask();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" style={{ background: '#2E3B55' }}>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <a style={{color: '#fff'}} href="https://warriorsnft.io">Warriors NFT</a>
          </Typography>
          <MenuItem>
            <NavLink style={styleLink} to="/mint">Mint</NavLink>
          </MenuItem>
          <MenuItem>
            <NavLink style={styleLink} to="/battle">Battle</NavLink>
          </MenuItem>
          <Button color="inherit" onClick={connect}>Connect</Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default Navigation;
