import React from 'react';
import Container from '@mui/material/Container';
import { Navigate } from 'react-router-dom';

const home = () => {
    return (
       <Container>
           <Navigate replace to="/battle" />
       </Container>
    );
}
 
export default home;
